import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes, Router } from '@angular/router';
import { JwtModule } from '@auth0/angular-jwt';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { AuthGuardService } from './auth/auth-guard.service';
import { UserService } from '@services/user.context/user.context.service';
import { LanguageService } from '@services/language/language.service';
import { AuthInterceptor } from './auth/auth.intercepter';
import { AppComponent } from './app.component';
import { PublicModule, publicRoutes } from './public/public.module';
import { environment } from '../environments/environment';
import { SharedModule } from '@shared';
import { createTranslateLoader, tokenGetter } from './app.helper';

export const appRoutes: Routes = [
  ...publicRoutes,
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
    canActivate: [AuthGuardService],
  },
];

const CLIENT_ID = environment.client_id;

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    PublicModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: true, useHash: false } // <-- debugging purposes only
    ),
    BrowserModule,
    BrowserAnimationsModule,
    JwtModule.forRoot({
      config: {
        tokenGetter,
      },
    }),
  ],
  providers: [
    LanguageService,
    AuthGuardService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: AuthGuardService,
      useFactory: (auth: UserService, router: Router) => new AuthGuardService(auth, router),
      deps: [UserService, Router],
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {
  constructor(translate: TranslateService) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('en');
  }
}
